$(document).ready(() => {
	const $aboutList = $('.about-list');

	const $navList = $('.nav-wrapper:not(.-breadcrumbs) .nav-list');
	const desktopSlickSettings = {
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [{
		    	breakpoint: 1100,
		    	settings: {
		        	slidesToShow: 2,
		        	slidesToScroll: 1,
		      	},
		    }, {
		    	breakpoint: 800,
		      	settings: {
		        	slidesToShow: 1,
		        	slidesToScroll: 1,
		    },
		}],
	};
	const clientsSlicSettings = {
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		}],
	};

	let lastId,
	    topMenu = $('header'),
	    topMenuHeight = topMenu.outerHeight() + 15,
	    menuItems = topMenu.find('.nav-wrapper:not(.-breadcrumbs) .nav-list a'),
	    scrollItems = menuItems.map(function() {
	    	let hash = $(this).attr('href');

	    	if (hash.indexOf('#') !== -1) {
				hash = hash.slice(2);
				if ($(hash).length) { return $(hash); }
	    	}	      	
	    });


	const bindHandlers = () => {
		$(window).scroll(function() {
			let fromTop = $(this).scrollTop() + topMenuHeight;
			let cur = scrollItems.map(function() {
				if ($(this).offset().top < fromTop)
					return this;
			});
			cur = cur[cur.length - 1];
			let id = cur && cur.length ? cur[0].id : '';
		   
			if (lastId !== id) {
				lastId = id;

				menuItems.parent()
					.removeClass('-active');

				menuItems.filter('[href="./#' + id + '"]')
					.parent()
					.addClass('-active');
		   }                   
		});
		$(window).on('resize', function() {
	        const width = $(window).width();
	        if (width < 960 && !$('.clients-list').hasClass('slick-initialized')) {
				$('.clients-list').slick(clientsSlicSettings);
			}
			if (width > 960 && $('.clients-list').hasClass('slick-initialized')) {
				$('.clients-list').slick('unslick');
			}
	      });
	};
	const initPlugins = () => {
		if ($(window).width() < 800 ) {
			$('.employee-list').slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,

			});
		} else {
			$('.employee-list').slick(desktopSlickSettings);
		}

		if ($(window).width() < 960 ) {
			$('.clients-list').slick(clientsSlicSettings);
		}
		$aboutList.slick({
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			responsive: 
				[{
			    	breakpoint: 1440,
			    	settings: {
			        	slidesToShow: 4,
			        	slidesToScroll: 1,
			      	},
			    }, {
			    	breakpoint: 1200,
			    	settings: {
			        	slidesToShow: 3,
			        	slidesToScroll: 1,
			      	},
			    }, {
			    	breakpoint: 960,
			      	settings: {
			        	slidesToShow: 2,
			        	slidesToScroll: 1,
			    	}, 
				}, {
			    	breakpoint: 800,
			      	settings: {
			        	slidesToShow: 1,
			        	slidesToScroll: 1,
			    	},
			    }],
		});
	};
	const initNav = () => {
		  const url = window.location.href;
		  const hash = url.substring(url.indexOf("#") + 1);
		  let $navLink;

		  if (hash === '' || hash === url)
		  	$navLink = $navList.find('[href="./#main"]');
		  else
		  	$navLink = $navList.find(`[href="./#${hash}"]`);

		  $navLink.parent()
		  		  .addClass('-active');	
	}
	
	const setViewport = () => {
        if (screen.width < 400) {
            $('#viewport').attr('content', 'width=400, maximum-scale=0.8');
        }
	}
	const init = () => {
		initPlugins();
		bindHandlers();
		initNav();
		setViewport();
	};

	init();
});
